.authBgImage {
  background-position: center;
  background-size:cover;
  background-repeat: no-repeat;
  background-image: url('../../assets/images/login.jpg');
  width: 100vw; 
  height: 100%; 
  min-height: 100vh; 
}

.bannerOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.bannerImg {
  background-image: url('../../assets/images/login.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.redOverlay {
  width: 100%;
  height: 100%;
  background: rgba(212, 0, 42, 0.7)
}

.noOverlay {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0)
}

.custom-file-upload {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  padding: 1.5rem;
}

.custom-file-upload .text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-align: center;
}

.custom-file-upload input {
  display: none;
}